import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import { useOpenContactModal } from '../../../hooks';
import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../../../constants';

import TechRewindCard from './components/TechRewindCard';
import TechRewindSubCard from './components/TechRewindSubCard';
import TechRewindCtaBanner from './components/TechRewindCtaBanner';

import lindaVid from '../../../images/tech-rewind-2024/videos/linda.mp4';
import lindaSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/linda.vtt';
import alexandreVid from '../../../images/tech-rewind-2024/videos/alexandre.mp4';
import alexandreSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/alexandre.vtt';
import mattVid from '../../../images/tech-rewind-2024/videos/matthew.mp4';
import mattSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/matthew.vtt';
import thibautVid from '../../../images/tech-rewind-2024/videos/thibaut.mp4';
import thibautSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/thibaut.vtt';

import styles from '../../common/styles/techRewind/techRewindCommon.module.scss';

const { MODAL: { CONTACT_US_BUTTON } } = GTM_KEYS;
const { TECH_REWIND_24: { SCALE } } = MODAL_SOURCE_BUTTONS;

const propTypes = {
    id: PropTypes.string.isRequired,
};

const TechRewindAtScale = ({ id }) => {
    const openContactModalPairMessage = useOpenContactModal(SCALE.PAIR, 'Hi, I’d like to start testing with Bluetooth. Can you take me through the options to activate it?');
    const openContactModalAiMessage = useOpenContactModal(SCALE.AI, "Hi, I’m interested in integrating AI into my video testing and monitoring. Can you share more about how it is used in Witbe’s technology?");

    const images = useStaticQuery(graphql`
        query TechRewind24AtScale {
            linda: file(relativePath : { eq: "tech-rewind-2024/videos/linda.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            alexandre: file(relativePath : { eq: "tech-rewind-2024/videos/alexandre.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            matthew: file(relativePath : { eq: "tech-rewind-2024/videos/matthew.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            thibaut: file(relativePath : { eq: "tech-rewind-2024/videos/thibaut.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            automation: file(relativePath : { eq: "tech-rewind-2024/mobile-automation.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        placeholder: NONE
                    )
                }
            },
            bluetooth: file(relativePath : { eq: "tech-rewind-2024/bluetooth.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
        }
    `);
    const lindaThumbnail = images.linda.childImageSharp.gatsbyImageData;
    const alexandreThumbnail = images.alexandre.childImageSharp.gatsbyImageData;
    const mattThumbnail = images.matthew.childImageSharp.gatsbyImageData;
    const thibautThumbnail = images.thibaut.childImageSharp.gatsbyImageData;
    const automation = images.automation.childImageSharp.gatsbyImageData;
    const bluetooth = images.bluetooth.childImageSharp.gatsbyImageData;

    return (
        <section id={id} className={styles.container}>
            <div className={styles.newIntro} data-sal='fade'>
                <h2>
                    We have big plans for you!<br/>
                    Our technology is built to deploy effortlessly on a global scale.
                </h2>
                <h3>Test and monitor remotely...</h3>
            </div>

            <TechRewindCard
                variant='blue'
                video={lindaVid}
                videoSubtitles={lindaSubtitles}
                videoThumbnail={lindaThumbnail}
                isWitbeingVideo
                isFlipped
                title={<h3>...on thousands of Smart TVs</h3>}
            >
                <p>
                    Witbe's technology <b>monitors Smart TVs like a human viewer</b>, proactively measuring video and audio quality across live and VoD streams from any video app.
                    <br /><br />
                    In 2024, we launched an upgraded Smart TV Automation set-up that’s <b>easy to install, stack, and made for industrial deployment.</b>
                    <br /><br />
                    A new calibration wizard and manual installation guide empowers any video service provider to <b>set it up independently.</b>
                    <br /><br />
                    Deploying Smart TV testing and monitoring has never been easier!
                </p>
            </TechRewindCard>
            <TechRewindSubCard
                variant='blue'
                title={<h3>...on thousands of mobile devices</h3>}
                image={automation}
            >
                <p>
                    In 2024, we are taking mobile testing to the next level with our new Video Mobile Automation technology! <b>Now you can test and monitor any app on two mobile devices.</b>
                    <br /><br />
                    Work seamlessly with both Android and iOS, no modifications needed.
                    <br /><br />
                    It is plug-and-play, easy to install and deploy globally, <b>making mobile control and testing fast and efficient.</b>
                    <br /><br />
                    Quickly analyze video availability and quality!
                </p>
            </TechRewindSubCard>

            <TechRewindCard
                variant='grey'
                video={alexandreVid}
                videoSubtitles={alexandreSubtitles}
                videoThumbnail={alexandreThumbnail}
                isWitbeingVideo
                className={styles.card}
                title={<h3>Excellence is invisible until it’s gone!<br />Consistently improving ease of use</h3>}
            >
                <p>
                    At Witbe, we always want to improve whatever takes our team and customers the most time.
                    <br /><br />
                    Our focus in 2024 was deploying our technology <b>further and faster</b>.
                    <br /><br />
                    This means not stopping until everything is running perfectly and our customers are fully satisfied. Every improvement of our features is designed to ensure faster deployment and better stability.
                    <br /><br />
                    <b>No detail is too small!</b>
                </p>
            </TechRewindCard>
            <TechRewindSubCard
                variant='grey'
                isAltVersion
                title={<h3>Strong Bluetooth</h3>}
                image={bluetooth}
            >
                <p><b>We boosted control stability</b> for Bluetooth and RF4CE devices, expanded compatibility to even more devices, and simplified connections with our brand-new calibration wizard.</p>
                <button type='button' onClick={openContactModalPairMessage} data-gtm-id={`${CONTACT_US_BUTTON}-${SCALE.PAIR}`}>Pair me up!</button>
            </TechRewindSubCard>

            <div className={`${styles.newIntro} ${styles.newBlock}`}>
                <h3>Stay on the cutting-edge of the industry</h3>
            </div>
            <TechRewindCard
                video={mattVid}
                videoSubtitles={mattSubtitles}
                videoThumbnail={mattThumbnail}
                isWitbeingVideo
                title={<h4>The power of AI in testing and monitoring</h4>}
            >
                <p>
                    Regular UI changes? No problem. Witbe’s <b>Smart Navigate</b> algorithm automatically adapts your tests.
                    <br/><br/>
                    In 2024, <b>it now supports new menu layouts</b>, including Android TV and Netflix reels, and four new types of cursors:
                </p>
                <ul>
                    <li>Text Highlight</li>
                    <li>Fixed Position</li>
                    <li>Magnified</li>
                    <li>Underlined</li>
                </ul>
                <p>
                    The new <b>Smart Menu Learner Wizard</b> autonomously captures any app's menu structure for seamless updates.
                    <br/><br/>
                    <b>Stay ahead of every update.</b>
                </p>
            </TechRewindCard>
            <TechRewindCard
                video={thibautVid}
                videoSubtitles={thibautSubtitles}
                videoThumbnail={thibautThumbnail}
                isWitbeingVideo
                title={<h4>Dynamic Ad Insertion challenges</h4>}
            >
                <p>
                    Getting on board with the latest Dynamic Ad Insertion tech?<br/>
                    We’ve got you covered!
                    <br/><br/>
                    Witbe’s <b>Slate Detection algorithm</b> now detects dynamic video slates, along with blank images and still screens.
                    <br/><br/>
                    <b>Our new OTT engine and correlator</b> detects ad slots with frame-level precision.
                    <br/><br/>
                    <b>Ad Matching</b>, Witbe's new fingerprinting algorithm, identifies ads in real time across any device and verifies errors like:
                </p>
                <ul>
                    <li>Ad repetition</li>
                    <li>Interrupted ads or content</li>
                    <li>Competitor ads</li>
                </ul>
            </TechRewindCard>
            <TechRewindCtaBanner
                buttonLabel='Upgrade to AI-powered testing'
                buttonDataGtmId={`${CONTACT_US_BUTTON}-${SCALE.AI}`}
                onClick={openContactModalAiMessage}
            >
                <p><b>Stay ahead of the game - deliver your best quality with Witbe!</b></p>
            </TechRewindCtaBanner>
        </section>
    );
}

TechRewindAtScale.propTypes = propTypes;

export default TechRewindAtScale;
