import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './input-email.module.scss';

const propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.string,
};

const InputEmail = ({ value, onChange, error, ...properties }) => {
    const hasError = error !== '';

    return (
        <div
            className={classNames(styles.inputWrapper, { [ styles.inputWrapperError ]: hasError })}
        >
            <input
                {...properties}
                type='email'
                name='email'
                id='email'
                onChange={onChange}
                value={value}
                autoComplete='true'
            />
            {hasError && <span>{error}</span>}
        </div>
    );
};

InputEmail.propTypes = propTypes;
InputEmail.defaultProps = {
    error: '',
};

export default InputEmail;
