import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../../common/styles/techRewind/techRewindBanners.module.scss';

const propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    buttonDataGtmId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

const TechRewindCtaBanner = ({ buttonLabel, buttonDataGtmId, onClick, children }) => (
    <div className={styles.banner} data-sal='fade'>
        {children}
        <button onClick={onClick} data-gtm-id={buttonDataGtmId}>{buttonLabel}</button>
    </div>
);

TechRewindCtaBanner.propTypes = propTypes;

export default TechRewindCtaBanner;
