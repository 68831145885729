import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../../../constants';

import { useOpenContactModal } from '../../../hooks';

import TechRewindBlueVideoCard from './components/TechRewindBlueVideoCard';
import TechRewindInteractiveCards from './components/TechRewindInteractiveCards';
import TechRewindCtaBanner from './components/TechRewindCtaBanner';
import TechRewindCard from './components/TechRewindCard';
import TechRewindFormBanner from './components/TechRewindFormBanner';

import gauthierVid from '../../../images/tech-rewind-2024/videos/gauthier.mp4';
import gauthierVidSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/gauthier.vtt';
import gauthierVidSquare from '../../../images/tech-rewind-2024/videos/gauthier_square.mp4';
import gauthierVidSquareSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/gauthier_square.vtt';

import anneSophieVid from '../../../images/tech-rewind-2024/videos/anne-sophie.mp4';
import anneSophieSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/anne-sophie.vtt';

import smartgateVid from '../../../images/tech-rewind-2024/vma-smartgate.mp4';

import styles from '../../common/styles/techRewind/techRewindCommon.module.scss';
import privacyStyles from '../../common/styles/techRewind/techRewindPrivacyPolicy.module.scss';

const {
    MODAL: { CONTACT_US_BUTTON },
    FORM: { SUBMIT },
} = GTM_KEYS;
const { TECH_REWIND_24: { AUTOMATED } } = MODAL_SOURCE_BUTTONS;

const propTypes = {
    id: PropTypes.string.isRequired,
};

const TechRewindAutomatedTesting = ({ id }) => {
    const openContactModalTryMessage = useOpenContactModal(AUTOMATED.TRY, 'Hi, I’m ready to access my local video streams from any web browser. Can you provide more information about this technology?')

    const images = useStaticQuery(graphql`
        query TechRewind24Automated {
            gauthier: file(relativePath : { eq: "tech-rewind-2024/videos/gauthier.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            anneSophie: file(relativePath : { eq: "tech-rewind-2024/videos/anne-sophie.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            error: file(relativePath : { eq: "tech-rewind-2024/error.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 640
                        placeholder: NONE
                    )
                }
            },
            trigger: file(relativePath : { eq: "tech-rewind-2024/trigger-new-test.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 640
                        placeholder: NONE
                    )
                }
            },
            matrix: file(relativePath : { eq: "tech-rewind-2024/comparison-matrix.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 640
                        placeholder: NONE
                    )
                }
            },
            share: file(relativePath : { eq: "tech-rewind-2024/smartgate-share-report.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 640
                        placeholder: NONE
                    )
                }
            }
        }
    `);
    const gauthierThumbnail = images.gauthier.childImageSharp.gatsbyImageData;
    const anneSophieThumbnail = images.anneSophie.childImageSharp.gatsbyImageData;
    const errorImage = images.error.childImageSharp.gatsbyImageData;
    const triggerImage = images.trigger.childImageSharp.gatsbyImageData;
    const matrixImage = images.matrix.childImageSharp.gatsbyImageData;
    const shareImage = images.share.childImageSharp.gatsbyImageData;

    return (
        <section id={id} className={styles.container}>
            <div className={styles.newIntro} data-sal='fade'>
                <h2>Automate your manual tests to get instantly notified of any issue</h2>
                <h3>Catch streaming errors on your mosaic as soon as they happen!</h3>
            </div>
            <TechRewindBlueVideoCard
                video={gauthierVid}
                videoSubtitles={gauthierVidSubtitles}
                videoSquare={gauthierVidSquare}
                videoSquareSubtitles={gauthierVidSquareSubtitles}
                videoThumbnail={gauthierThumbnail}
            />
            <TechRewindInteractiveCards
                variant='blue'
                content={[
                    {
                        image: errorImage,
                        text: <p>NOC teams can now instantly visualize an error in red on the mosaic and shorten their response time to resolve them.</p>,
                    },
                    {
                        image: triggerImage,
                        text: <p>When a problem strikes, enter the device and instantly trigger a new test to verify the status of the issue.</p>,
                    },
                ]}
            />
            <TechRewindCtaBanner
                buttonLabel='Try it today!'
                buttonDataGtmId={`${CONTACT_US_BUTTON}-${AUTOMATED.TRY}`}
                onClick={openContactModalTryMessage}
            >
                <p><b>Ready to control your local streams from any web browser?</b></p>
            </TechRewindCtaBanner>
            <TechRewindCard
                variant='grey'
                video={anneSophieVid}
                videoSubtitles={anneSophieSubtitles}
                videoThumbnail={anneSophieThumbnail}
                isWitbeingVideo
                title={<h3>Actionable, detailed, and organized data?  Yes, please!</h3>}
            >
                <p>The Witbox <b>automatically streams Key Performance Indicators</b> (KPIs) into <b>Smartgate</b>, Witbe’s observability platform, to:</p>
                <ul>
                    <li>Monitor services proactively</li>
                    <li>Track improvements over time</li>
                    <li>Inspect alerts and video recordings when issues are detected</li>
                </ul>
                <p>In 2024, you can <b>access real-time video and instant reports</b> during tests, no waiting required. Use Smartgate to replay the Witbox measurements and diagnose errors.</p>

                <p>With the new History View, you can generate reports on:</p>
                <ul>
                    <li>User controls, to track which Witbox and devices are most used</li>
                    <li>Reboots, to identify abnormal behavior</li>
                    <li>Version status, to maintain up-to-date systems</li>
                </ul>
            </TechRewindCard>
            <TechRewindInteractiveCards
                variant='grey'
                content={[
                    {
                        image: matrixImage,
                        text: <p>Witbe 24.7 lets you customize and share your Comparison Matrix with key KPIs and contexts for smarter deployment decisions.</p>,
                    },
                    {
                        image: shareImage,
                        text: <p>With the new email reporting engine, share dashboards with precise metrics and actionnable reports with your teams.</p>,
                    },
                ]}
            />
            <TechRewindFormBanner
                buttonLabel='Send report'
                buttonDataGtmId={`${SUBMIT}-${AUTOMATED.REPORT}`}
            >
                <p>
                    <b>Ready for a preview of what your weekly KPI report could look like? </b>
                    <sup className={privacyStyles.small}>1</sup>
                </p>
            </TechRewindFormBanner>
            <TechRewindCard
                title={<h3>Optimize your data usage without compromising on video or service quality</h3>}
                video={smartgateVid}
                isFlipped
            >
                <p>
                    <b>Get an accurate measurement of data usage across video resolutions, app builds, devices, and networks.</b>
                    <br/><br/>
                    In Smartgate:
                </p>
                <ul>
                    <li>Compare your service to the competition with Benchmarking</li>
                    <li>Optimize your bandwidth usage to stay competitive</li>
                    <li>Increase sustainability</li>
                    <li>Offer your viewers the best Quality of Experience possible</li>
                </ul>
            </TechRewindCard>
        </section>
    );
}

TechRewindAutomatedTesting.propTypes = propTypes;

export default TechRewindAutomatedTesting;
