import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Link from '../../../common/Link';

import styles from '../../../common/styles/techRewind/techRewindBento.module.scss';

const TechRewindBento = () => (
    <div className={styles.bento}>
        <div className={`${styles.card} ${styles.lg} ${styles.topLeft}`} data-sal='fade'>
            <div className={styles.awards}>
                <Link to='/articles/witbes-rec-monitoring-tool-and-analysis-tool-wins-4-diamonds/' target='_blank' external>
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/REC-btr-diamond-technology-reviews-award-2023.png'
                        alt='2023 BTR Diamond Reviews Award - Monitoring and Analysis'
                        height={72}
                    />
                </Link>
                <Link
                    to='https://www.streamingmediaglobal.com/Articles/Editorial/Featured-Articles/The-2023-Streaming-Media-Innovation-Award-Winners-160125.aspx'
                    target='_blank'
                    external
                >
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/streaming-media-european-innovation-award.png'
                        alt='Streaming Media European Innovation Awards - QoS/QoE Platform'
                        height={72}
                    />
                </Link>
            </div>
            <h4>Remote Eye Controller</h4>
        </div>
        <div className={`${styles.card} ${styles.lg} ${styles.topRight}`} data-sal='fade' data-sal-delay='200'>
            <div className={styles.awards}>
                <Link to='/articles/ad-monitoring-nab-product-of-the-year-award-2024/' target='_blank' external>
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/nab-product-year-award-2024.png'
                        alt='NAB Show Product of the year Award - Measuring Tool'
                        height={72}
                    />
                </Link>
                <Link to='/articles/csi-award-ad-monitoring-2024/' target='_blank' external>
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/csi-award-2024.png'
                        alt='CSI Award - Best Advance in AdTech or Monetization Technology'
                        height={72}
                    />
                </Link>
                <Link to='/articles/dtr-award-ad-monitoring-2024/' target='_blank' external>
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/ADS-dtr-diamond-technology-reviews-award-2024.png'
                        alt='DTR Diamond Reviews Award - Monitoring and Analysis'
                        height={72}
                    />
                </Link>
            </div>
            <h4>Ad Monitoring and Matching</h4>
        </div>
        <div className={`${styles.card} ${styles.sm} ${styles.bottomLeft}`} data-sal='fade' data-sal-delay='400'>
            <div className={styles.awards}>
                <Link to='/articles/witbe-witbox-wins-2023-nab-show-product-of-the-year-award/' target='_blank' external>
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/nab-product-year-award-2023.png'
                        alt='NAB Show Product of the year Award - New Product'
                        height={72}
                    />
                </Link>
            </div>
            <h4>Witbox+</h4>
        </div>
        <div className={`${styles.card} ${styles.sm}`} data-sal='fade' data-sal-delay='600'>
            <div className={styles.awards}>
                <Link to='/articles/smart-navigate-nab-best-of-show-award-2024/' target='_blank' external>
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/tv-tech-best-of-show-2024.png'
                        alt='TvTech Best of Show 2024 Winner Award - NAB Las Vegas - Outstanding products and solutions in the technology and media industries on show at NAB'
                        height={72}
                    />
                </Link>
            </div>
            <h4>Smart Navigate AI Algorithm</h4>
        </div>
        <div className={`${styles.card} ${styles.sm} ${styles.bottomRight}`} data-sal='fade' data-sal-delay='800'>
            <div className={styles.awards}>
                <Link to='/articles/best-of-show-award-ibc-mobile-automation-2024/' target='_blank' external>
                    <StaticImage
                        src='../../../../images/tech-rewind-2024/awards/tv-tech-best-of-show-2024.png'
                        alt='TvTech Best of Show 2024 Winner Award - IBC - Broadcast products and solutions on display'
                        height={72}
                    />
                </Link>
            </div>
            <h4>Video Mobile Automation</h4>
        </div>
    </div>
);

export default TechRewindBento;
