import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LazyLoad from 'react-lazyload';

import Video from '../../../ui/Video';

import styles from '../../../common/styles/techRewind/techRewindCard.module.scss';

const propTypes = {
    title: PropTypes.node,
    video: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf([ 'blue', 'grey', 'transparent', 'full' ]),
    videoSubtitles: PropTypes.string,
    videoThumbnail: PropTypes.object,
    isWitbeingVideo: PropTypes.bool,
    isFlipped: PropTypes.bool,
    isHollowButton: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    title: null,
    variant: 'full',
    videoSubtitles: null,
    videoThumbnail: null,
    isWitbeingVideo: false,
    isFlipped: false,
    isHollowButton: false,
    className: '',
};

const TechRewindCard = ({ title, video, children, variant, videoSubtitles, videoThumbnail, isWitbeingVideo, isFlipped, isHollowButton, className }) => (
    <div
        className={classNames(
            styles.mainCard,
            {
                [styles[variant]]: variant,
                [styles.flipped]: isFlipped,
                [styles.witbeingVideo]: variant === 'full' && isWitbeingVideo,
                [styles.hollowButton]: isHollowButton,
            },
            className,
        )}
        data-sal='fade'
    >
        <div className={classNames(!title && styles.noTitle)}>
            <LazyLoad className={styles.video}>
                <Video
                    video={video}
                    subtitles={videoSubtitles}
                    poster={videoThumbnail ? videoThumbnail.images.fallback.src : null}
                    loop={!isWitbeingVideo}
                    autoPlay={!isWitbeingVideo}
                    muted={!isWitbeingVideo}
                    controls={isWitbeingVideo}
                    disableremoteplayback='true'
                />
            </LazyLoad>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.text}>
                {children}
            </div>
        </div>
    </div>
);

TechRewindCard.propTypes = propTypes;
TechRewindCard.defaultProps = defaultProps;

export default TechRewindCard;
