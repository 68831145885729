import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Video from '../../ui/Video';
import TechRewindBlueVideoCard from './components/TechRewindBlueVideoCard';

import introVid from '../../../images/tech-rewind-2024/videos/mathieu.mp4';
import introVidSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/mathieu.vtt';
import introVidSquare from '../../../images/tech-rewind-2024/videos/mathieu_square.mp4';
import introVidSquareSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/mathieu_square.vtt';

import mosaicVid from '../../../images/tech-rewind-2024/mosaic.mp4';

import techRewindStyles from '../../../pages/styles/techrewind.module.scss';
import styles from '../../common/styles/techRewind/techRewindIntro.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
};

const TechRewindIntro = ({ id }) => {
    const images = useStaticQuery(graphql`
        query TechRewind24Intro {
            mathieu: file(relativePath : { eq: "tech-rewind-2024/videos/mathieu.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 1080
                        placeholder: NONE
                    )
                }
            }
        }
    `);
    const mathieuThumbnail = images.mathieu.childImageSharp.gatsbyImageData;

    return (
        <section id={id} className={styles.container}>
            <div className={styles.intro} data-sal='fade'>
                <h1 className={`${techRewindStyles.introTextTitle} ${techRewindStyles.blue} ${styles.introTextTitle}`}>2025 Starts Here</h1>
                <p className={`${techRewindStyles.introTextSub} ${styles.introTextSub}`}>
                    <b>Welcome to Witbe!</b> We specialize in video testing and monitoring. Our technology empowers video service providers and operators to deliver the best Quality of Experience (QoE) to their viewers. Our unique technology lets you test on the same devices viewers use to watch your content around the world, including&nbsp;TVs, Set-Top Boxes, and mobile phones.
                </p>
                <div className={`${techRewindStyles.animateWrapperDesktop} ${styles.animateWrapperDesktop}`}>
                    <GatsbyImage
                        image={mathieuThumbnail}
                        alt='Witbe Tech Rewind 2024 Intro'
                        className={techRewindStyles.placeholderWrapper}
                    />
                    <LazyLoad>
                        <Video
                            autoPlay
                            controls
                            video={introVid}
                            videoMobile={introVidSquare}
                            subtitles={introVidSubtitles}
                            subtitlesMobile={introVidSquareSubtitles}
                        />
                    </LazyLoad>
                </div>
            </div>
            <div className={styles.virtualNoc}>
                <h2
                    className={styles.virtualNocTitle}
                    data-sal='fade'
                    data-sal-duration='1250'
                >
                    <span className={`${techRewindStyles.introTextTitle} ${techRewindStyles.white}`}>Discover our biggest REC&nbsp;update!</span>
                    <span className={`${techRewindStyles.introTextTitle} ${techRewindStyles.blue}`}>Witbe’s new virtual&nbsp;NOC</span>
                </h2>
                <TechRewindBlueVideoCard video={mosaicVid} isDecorative />
                <div className={styles.recCards}>
                    <div data-sal='fade'>
                        <h3>Spectacular design</h3>
                        <p>An upgraded design keeps your video streams easy to navigate and monitor.</p>
                    </div>
                    <div data-sal='fade' data-sal-delay='200'>
                        <h3>Fully customizable</h3>
                        <p>Make the virtual NOC your own with personalized tiles, clusters, and filters.</p>
                    </div>
                    <div data-sal='fade' data-sal-delay='400'>
                        <h3>User-friendly features</h3>
                        <p>Your feedback shaped our latest innovations that you’ll find indispensable!</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

TechRewindIntro.propTypes = propTypes;

export default TechRewindIntro;
