import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../../../constants';

import { useOpenContactModal } from '../../../hooks';

import TechRewindCard from './components/TechRewindCard';
import TechRewindBento from './components/TechRewindBento';

import vincentVid from '../../../images/tech-rewind-2024/videos/vincent.mp4';
import vincentSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/vincent.vtt';
import maelleVid from '../../../images/tech-rewind-2024/videos/maelle.mp4';
import maelleSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/maelle.vtt';

import styles from '../../common/styles/techRewind/techRewindCommon.module.scss';

const { MODAL: { CONTACT_US_BUTTON } } = GTM_KEYS;
const { TECH_REWIND_24: { INDEPENDENTLY} } = MODAL_SOURCE_BUTTONS;

const propTypes = {
    id: PropTypes.string.isRequired,
};

const TechRewindIndependently = ({ id }) => {
    const openContactModalCertificationMessage = useOpenContactModal(INDEPENDENTLY.CERTIFICATION, 'Hi, I’d like to get Witbe certified. Could you tell me about the process and requirements?');

    const images = useStaticQuery(graphql`
        query TechRewind24Independently {
            vincent: file(relativePath : { eq: "tech-rewind-2024/videos/vincent.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            maelle: file(relativePath : { eq: "tech-rewind-2024/videos/maelle.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            }
        }
    `);
    const vincentThumbnail = images.vincent.childImageSharp.gatsbyImageData;
    const maelleThumbnail = images.maelle.childImageSharp.gatsbyImageData;

    return (
        <section id={id} className={styles.container}>
            <div className={styles.newIntro} data-sal='fade'>
                <h2>Giving you the keys to work independently</h2>
            </div>

            <TechRewindCard
                variant='blue'
                video={vincentVid}
                videoSubtitles={vincentSubtitles}
                videoThumbnail={vincentThumbnail}
                isWitbeingVideo
                isFlipped
                isHollowButton
                title={<h3>Become a certified Witbe expert!</h3>}
            >
                <p>
                    Our new <b>Witbe Certification Program</b> teaches you how to get the most out of our technology, whether you’re already an advanced user or just getting started.
                    <br /><br />
                    Our new training sessions cover the latest standards and best practices to help you <b>resolve issues effectively with Witbe’s technology.</b>
                    <br /><br />
                    Sign up for our online classes to get your Witbe certification!
                    <br /><br />
                    Happy Learning!
                </p>
                <button onClick={openContactModalCertificationMessage} data-gtm-id={`${CONTACT_US_BUTTON}-${INDEPENDENTLY.CERTIFICATION}`}>Get certified today!</button>
            </TechRewindCard>

            <TechRewindCard
                variant='grey'
                video={maelleVid}
                videoSubtitles={maelleSubtitles}
                videoThumbnail={maelleThumbnail}
                isWitbeingVideo
                isHollowButton
                className={styles.card}
                title={<h3>Know it all with Witbe’s Webinar series!</h3>}
            >
                <p>
                    Check out Witbe’s webinar series for the latest on our technology and <b>practical insights</b> from the people who make it.
                    <br /><br />
                    Watch our most recent session about our virtual NOC!
                </p>
                <a
                    href="https://app.livestorm.co/witbe/witbes-virtual-noc-replay?s=857a8b4e-49e7-44d9-ad2d-c64950c2183e"
                    data-gtm-id={`${CONTACT_US_BUTTON}-${INDEPENDENTLY.NEWSLETTER}`}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Discover the virtual NOC
                </a>
            </TechRewindCard>

            <div className={`${styles.newIntro} ${styles.awardsIntro}`} data-sal='fade'>
                <h3>Our award-winning technology</h3>
                <TechRewindBento />
            </div>
        </section>
    );
}

TechRewindIndependently.propTypes = propTypes;

export default TechRewindIndependently;
