import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';

import Video from '../../../ui/Video';

import { useViewport } from '../../../../hooks';

import styles from '../../../common/styles/techRewind/techRewindBlueVideoCard.module.scss';

const propTypes = {
    video: PropTypes.string.isRequired,
    videoSubtitles: PropTypes.string,
    videoSquare: PropTypes.string,
    videoSquareSubtitles: PropTypes.string,
    videoThumbnail: PropTypes.object,
    isDecorative: PropTypes.bool,
};

const defaultProps = {
    videoSubtitles: null,
    videoSquare: null,
    videoSquareSubtitles: null,
    videoThumbnail: null,
    isDecorative: false,
};

const TechRewindBlueVideoCard = ({
    video,
    videoSubtitles,
    videoSquare,
    videoSquareSubtitles,
    videoThumbnail,
    isDecorative
}) => {
    const { isSmallBreakpoint } = useViewport();

    return (
        <div className={classNames(styles.card, videoSquare && styles.hasSquare)} data-sal='fade'>
            <LazyLoad className={styles.video}>
                {
                    // A simple ternary doesn't change the source video when resizing
                    isSmallBreakpoint && videoSquare && (
                        <Video
                            video={videoSquare}
                            subtitles={videoSquareSubtitles}
                            poster={videoThumbnail ? videoThumbnail.images.fallback.src : null}
                            loop={false}
                            muted={isDecorative}
                            autoPlay={isDecorative}
                            controls={!isDecorative}
                            disableremoteplayback='true'
                        />
                    )
                }
                {
                    (!isSmallBreakpoint || !videoSquare) && (
                        <Video
                            video={video}
                            subtitles={videoSubtitles}
                            poster={videoThumbnail ? videoThumbnail.images.fallback.src : null}
                            loop={false}
                            muted={isDecorative}
                            autoPlay={isDecorative}
                            controls={!isDecorative}
                            disableremoteplayback='true'
                        />
                    )
                }
            </LazyLoad>
        </div>
    )
};

TechRewindBlueVideoCard.propTypes = propTypes;
TechRewindBlueVideoCard.defaultProps = defaultProps;

export default TechRewindBlueVideoCard;
