import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import styles from '../../../common/styles/techRewind/techRewindInteractiveCards.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf([ 'blue', 'grey' ]).isRequired,
    index: PropTypes.number.isRequired,
};

const TechRewindInteractiveCard = ({ children, variant, index }) => {
    const [isActive, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive(active => !active), []);

    return (
        <div
            className={`${styles.card} ${styles[variant]}`}
            data-active={isActive}
            onClick={toggleActive}
            data-sal='fade'
            data-sal-delay={index ? `${index * 300}ms` : null}>
            {children}
        </div>
    );
};

TechRewindInteractiveCard.propTypes = propTypes

export default TechRewindInteractiveCard;
