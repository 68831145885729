import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import TechRewindInteractiveCard from './TechRewindInteractiveCard';

import styles from '../../../common/styles/techRewind/techRewindInteractiveCards.module.scss';

const propTypes = {
    content: PropTypes.arrayOf(PropTypes.shape({
        image: PropTypes.object.isRequired,
        text: PropTypes.node.isRequired,
    })).isRequired,
    variant: PropTypes.oneOf([ 'blue', 'grey' ]).isRequired,
};

const TechRewindInteractiveCards = ({ content, variant }) => (
    <div className={styles.grid}>
        {content.map(({ image, text }, index) => (
            <TechRewindInteractiveCard key={image.images.sources[0].srcSet} variant={variant} index={index}>
                {text}
                <GatsbyImage
                    image={image}
                    alt=''
                    className={styles.image}
                />
            </TechRewindInteractiveCard>
        ))}
    </div>
);

TechRewindInteractiveCards.propTypes = propTypes;

export default TechRewindInteractiveCards;
