import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';

import Link from '../../../common/Link';
import styles from '../../../common/styles/techRewind/techRewindBanners.module.scss';
import { useSendReport } from '../../../../hooks/techRewind/useSendReport';
import StatusButton from '../../../ui/StatusButton/StatusButton';
import InputEmail from '../../../ui/InputEmail/InputEmail';
import { isEmailAddress, isEmailValid } from '../../../../utils/validation';
import { CAPTCHA_PUBLIC_KEY, CAPTCHA_PUBLIC_KEY_DEBUG } from '../../../../constants/forms';
import { useClickOutside } from '../../../../hooks';

const propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    buttonDataGtmId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isDebug: PropTypes.bool,
};

const TechRewindFormBanner = ({ buttonLabel, buttonDataGtmId, children, isDebug }) => {
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ isSuccessAnimation, setIsSuccessAnimation ] = useState(false);
    const [ isCaptchaVisible, setCaptchaVisible ] = useState(false);

    const captchaContainerReference = useClickOutside(() => setCaptchaVisible(false), isCaptchaVisible);
    const captchaReference = useRef(null);

    const onReportSent = useCallback(() => {
        setIsSuccessAnimation(true);
        setTimeout(() => {
            setIsSuccessAnimation(false);
            setEmail('');
        }, 3000);
    }, []);

    const {
        onSendReport,
        resetState: resetApiError,
        isLoading,
        hasError: hasApiError
    } = useSendReport(onReportSent);

    const errorMessage = emailError
        ? emailError
        : hasApiError ? 'Sorry, something went wrong. Please try again later.' : '';

    const onEmailChange = useCallback(event => {
        const { value } = event.target;
        let newErrorMessage = '';

        // Early warning if the user types a personal email
        if (value && !isEmailValid(value)) {
            newErrorMessage = 'We only accept professional email addresses.';
        }

        setEmailError(newErrorMessage);
        setEmail(value);

        if (hasApiError) {
            resetApiError();
        }
    }, [ hasApiError, resetApiError ]);

    const onFormSubmit = useCallback(async event => {
        event.preventDefault();

        if (!email) {
            setEmailError('Please enter an email address.');
            return;
        }
        if (!isEmailAddress(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }
        if (emailError) {
            return;
        }

        setCaptchaVisible(true);
    }, [ email, emailError, onSendReport ]);

    const onCaptchaSubmit = useCallback(async token => {
        setCaptchaVisible(false);
        await onSendReport(email, token);
        captchaReference.current?.reset();
    }, [ email, onSendReport ]);

    return (
        <div className={styles.banner} data-sal='fade'>
            {children}
            <form action='#' onSubmit={onFormSubmit}>
                <InputEmail
                    placeholder='business-email@company.com'
                    onChange={onEmailChange}
                    value={email}
                    error={errorMessage}
                />
                <div className={styles.buttonWrapper}>
                    <StatusButton
                        isLoading={isLoading}
                        isSuccess={isSuccessAnimation}
                        isDefault={!isLoading && !isSuccessAnimation}
                        loadingText='Sending report'
                        successText='Report sent'
                        defaultText={buttonLabel}
                        data-gtm-id={buttonDataGtmId}
                    />
                    <div
                        ref={captchaContainerReference}
                        className={classNames(styles.captcha, { [ styles.visible ]: isCaptchaVisible })}
                    >
                        <span>To receive the report, please complete the CAPTCHA below.</span>
                        <ReCAPTCHA
                            sitekey={isDebug ? CAPTCHA_PUBLIC_KEY_DEBUG : CAPTCHA_PUBLIC_KEY}
                            onChange={onCaptchaSubmit}
                            theme='dark'
                            ref={captchaReference}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

TechRewindFormBanner.propTypes = propTypes;
TechRewindFormBanner.defaultProps = {
    isDebug: false,
};

export default TechRewindFormBanner;
