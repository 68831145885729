import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../common/styles/techRewind/techRewindSubCard.module.scss';
import cardStyles from '../../../common/styles/techRewind/techRewindCard.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

const propTypes = {
    title: PropTypes.node.isRequired,
    image: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf([ 'blue', 'grey', 'transparent' ]).isRequired,
    isAltVersion: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    isAltVersion: false,
    className: '',
};

const TechRewindSubCard = ({ title, image, children, variant, isAltVersion, className }) => (
    <div
        className={classNames(
            styles.subCard,
            styles[variant],
            isAltVersion && styles.altVersion,
            className,
        )}
        data-sal='fade'
    >
        <div className={styles.textContainer}>
            <div className={cardStyles.title}>{title}</div>
            <div className={cardStyles.text}>
                {children}
            </div>
        </div>
        <GatsbyImage image={image} className={styles.image} alt='' objectFit='contain' />
    </div>
);

TechRewindSubCard.propTypes = propTypes;
TechRewindSubCard.defaultProps = defaultProps;

export default TechRewindSubCard;
