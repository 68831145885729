/* eslint-disable react/jsx-pascal-case*/
/* eslint-disable react/function-component-definition*/
/* eslint-disable import/no-unused-modules*/
import React, { useEffect } from 'react';
import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import ogimage from '../images/tech-rewind-2024/tech-rewind-asset-og.jpg';

import TechRewindNav from '../components/techRewind/TechRewindNav';
import TechRewindIntro from '../components/techRewind/2024/TechRewindIntro';
import TechRewindManualTesting from '../components/techRewind/2024/TechRewindManualTesting';
import TechRewindAutomatedTesting from '../components/techRewind/2024/TechRewindAutomatedTesting';
import TechRewindAtScale from '../components/techRewind/2024/TechRewindAtScale';
import TechRewindIndependently from '../components/techRewind/2024/TechRewindIndependently';
import TechRewindCelebration from '../components/techRewind/2024/TechRewindCelebration';

import styles from '../components/common/styles/techRewind/techRewindCommon.module.scss';

const noScriptFallbackCss = `
    [data-sal|='fade'] {
        opacity: 1;
    }
`;

const anchors = ['welcome', 'manualTesting', 'automatedTesting', 'scale', 'independently', 'celebration'];

export default function TechRewind2024() {
    return (
        <Layout title='2025 Starts Here: Discover Witbe’s Tech Innovations' variant='tech-rewind-2024' bodyClass={styles.techRewind}>
            <SEO
                title='2025 Starts Here: Discover Witbe’s Tech Innovations'
                ogDescription='Discover Witbe’s tech innovations, including our virtual NOC, Smart TV Automation set-up, and Video Mobile Automation.'
                description='Discover Witbe’s tech innovations, including our virtual NOC, Smart TV Automation set-up, and Video Mobile Automation.'
                image={ogimage}
            />

            <TechRewindNav year={2024} anchors={anchors} highlightCurrentSection />
            <TechRewindIntro id='welcome' />
            <TechRewindManualTesting id='manualTesting' />
            <TechRewindAutomatedTesting id='automatedTesting' />
            <TechRewindAtScale id='scale' />
            <TechRewindIndependently id='independently' />
            <TechRewindCelebration id='celebration' />

            <noscript><style type='text/css'>{noScriptFallbackCss}</style></noscript>
        </Layout>
    );
}
