import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../../../constants';

import { useOpenContactModal } from '../../../hooks';

import TechRewindCard from './components/TechRewindCard';

import antoineVid from '../../../images/tech-rewind-2024/videos/antoine.mp4';
import antoineSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/antoine.vtt';
import jimmyVid from '../../../images/tech-rewind-2024/videos/jimmy.mp4';
import jimmySubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/jimmy.vtt';

import voiceVid from '../../../images/tech-rewind-2024/voice.mp4';

import styles from '../../common/styles/techRewind/techRewindCommon.module.scss';

const { MODAL: { CONTACT_US_BUTTON } } = GTM_KEYS;
const { TECH_REWIND_24: { MANUAL } } = MODAL_SOURCE_BUTTONS;

const propTypes = {
    id: PropTypes.string.isRequired,
};

const TechRewindManualTesting = ({ id }) => {
    const openContactModalBuildMessage = useOpenContactModal(MANUAL.BUILD, 'Hi, I’m interested in building my own virtual NOC with Witbe\'s technology. Can you provide more information and schedule a demo?')
    const openContactModalRecordMessage = useOpenContactModal(MANUAL.RECORD, 'Hi, I’d like to learn more about your virtual NOC technology and the recording feature. Can you send me more information?')

    const images = useStaticQuery(graphql`
        query TechRewind24Manual {
            antoine: file(relativePath : { eq: "tech-rewind-2024/videos/antoine.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            },
            jimmy: file(relativePath : { eq: "tech-rewind-2024/videos/jimmy.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 600
                        placeholder: NONE
                    )
                }
            }
        }
    `);
    const antoineThumbnail = images.antoine.childImageSharp.gatsbyImageData;
    const jimmyThumbnail = images.jimmy.childImageSharp.gatsbyImageData;

    return (
        <section id={id} className={styles.container}>
            <div className={styles.intro}>
                <h2 data-sal='fade'>Manually test video services in the field or the lab from a web app</h2>
                <TechRewindCard
                    video={voiceVid}
                    isFlipped
                    className={styles.fullCard}
                >
                    <p>
                        Witbe’s Remote Eye Controller (REC) is a virtual Network Operations Center (NOC). It gives you <b>real-time visibility and control over your devices in the field or the lab</b>, as if you were physically in front of them.
                        <br /><br />
                        See what is happening at the end-user level to diagnose and resolve issues quickly.
                        <br /><br />
                        From <b>voice command</b> support to <b>mobile device</b> control, the REC simplifies and speeds up collaborative troubleshooting.
                    </p>
                </TechRewindCard>
            </div>
            <TechRewindCard
                variant='grey'
                video={antoineVid}
                videoSubtitles={antoineSubtitles}
                videoThumbnail={antoineThumbnail}
                isWitbeingVideo
                className={styles.card}
                title={<h3>Fully personalize your virtual NOC</h3>}
            >
                <p>You can now <b>customize and tailor the REC</b> to fit your daily operations:</p>
                <ul>
                    <li>Maximize the video to focus on what happens in the stream</li>
                    <li>Display device activity details to immediately see what is being tested</li>
                </ul>
                <p><b>Easily find the devices you use the most</b> by adding them to favorites, creating dynamic clusters, adjusting the grid size, and filtering devices that are in maintenance or already being controlled by someone else.</p>
                <p><br /><b>Display as many devices as you want</b> with an infinite scroll.</p>
                <button onClick={openContactModalBuildMessage} data-gtm-id={`${CONTACT_US_BUTTON}-${MANUAL.BUILD}`}>Build my own virtual NOC</button>
            </TechRewindCard>
            <TechRewindCard
                variant='transparent'
                video={jimmyVid}
                videoSubtitles={jimmySubtitles}
                videoThumbnail={jimmyThumbnail}
                isWitbeingVideo
                className={styles.card}
                title={
                    <h3>
                        Stop arguing, start solving!<br />
                        Click, record, and share evidence of what you see.
                    </h3>
                }
            >
                <p>
                    Debating if what you saw is true or not when other teams or partners can’t replicate the error can be frustrating and cumbersome.
                    <br /><br />
                    With the REC's new <b>Record button</b>, simply click on any video stream in the Remote Eye Controller to <b>save exactly what you see</b>, then <b>share it</b> with teams or partners to <b>resolve issues faster</b>.
                    <br /><br />
                    Shared recordings are perfect for teams to speak the same language and solve problems together.
                </p>
                <button onClick={openContactModalRecordMessage} data-gtm-id={`${CONTACT_US_BUTTON}-${MANUAL.RECORD}`}>Start recording!</button>
            </TechRewindCard>
        </section>
    );
}

TechRewindManualTesting.propTypes = propTypes;

export default TechRewindManualTesting;
