import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ModalContext } from '../../../context';
import Link from '../../common/Link';

import { GTM_KEYS,  MODAL_TYPES, MODAL_SOURCE_BUTTONS } from '../../../constants';

import Video from '../../ui/Video';
import TechRewindCtaBanner from './components/TechRewindCtaBanner';

import mvlVid from '../../../images/tech-rewind-2024/videos/mvl.mp4';
import mvlVidSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/mvl.vtt';
import mvlVidSquare from '../../../images/tech-rewind-2024/videos/mvl_square.mp4';
import mvlVidSquareSubtitles from 'file-loader!../../../images/tech-rewind-2024/videos/mvl_square.vtt';


import techRewindStyles from '../../../pages/styles/techrewind.module.scss';
import styles from '../../common/styles/techRewind/techRewindIntro.module.scss';
import privacyStyles from '../../common/styles/techRewind/techRewindPrivacyPolicy.module.scss';

const { MODAL: { NEWSLETTER_BUTTON } } = GTM_KEYS;
const { NEWSLETTER } = MODAL_TYPES;
const { TECH_REWIND_24 } = MODAL_SOURCE_BUTTONS;

const propTypes = {
    id: PropTypes.string.isRequired,
};

const TechRewindCelebration = ({ id }) => {
    const { openModal } = useContext(ModalContext);
    const openNewsletterModal = useCallback(
        () => openModal({ modalType: NEWSLETTER, sourceButton: TECH_REWIND_24.CELEBRATION }),
        [ openModal ],
    );

    const images = useStaticQuery(graphql`
        query TechRewind24Celebration {
            mvl: file(relativePath : { eq: "tech-rewind-2024/videos/mvl.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        height: 650
                        placeholder: NONE
                    )
                }
            },
        }
    `);
    const mvlThumbnail = images.mvl.childImageSharp.gatsbyImageData;

    return (
        <section id={id} className={styles.container}>
            <h2 className={styles.virtualNocTitle} data-sal='fade'>
                <span className={`${techRewindStyles.introTextTitle} ${techRewindStyles.blue}`}>Celebrate with us as Witbe turns 25 in 2025!</span>
            </h2>
            <div className={`${techRewindStyles.animateWrapperDesktop} ${styles.animateWrapperDesktop}`} data-sal='fade'>
                <GatsbyImage
                    image={mvlThumbnail}
                    alt='Witbe Tech Rewind 2024 Outro'
                    className={techRewindStyles.placeholderWrapper}
                />
                <LazyLoad>
                    <Video
                        video={mvlVid}
                        videoMobile={mvlVidSquare}
                        subtitles={mvlVidSubtitles}
                        subtitlesMobile={mvlVidSquareSubtitles}
                        poster={mvlThumbnail}
                        controls
                        disableremoteplayback='true'
                    />
                </LazyLoad>
            </div>
            <TechRewindCtaBanner
                buttonLabel="Let's party!"
                buttonDataGtmId={`${NEWSLETTER_BUTTON}-${TECH_REWIND_24.CELEBRATION}`}
                onClick={openNewsletterModal}
            >
                <p><b>Join Witbe’s newsletter to know all about our anniversary celebrations!</b></p>
            </TechRewindCtaBanner>
            <p className={privacyStyles.privacy}>
                <sup>1</sup>
                {' By providing my email address, I consent to being contacted by Witbe and receiving a sample report. For more details on how we use your data, please see our '}
                <Link to='/policy/' target='_blank' external>Privacy Policy</Link>
                .
            </p>
        </section>
    );
}

TechRewindCelebration.propTypes = propTypes;

export default TechRewindCelebration;
